@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');



*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

/* General classes */
.fixed{
  position:fixed
}
.absolute{
  position: absolute;
}
.relative{
  position: relative;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.center-cross{
  align-items: center;
}
.center-main{
  justify-content: center;
}
.center-all{
  align-items: center;
  justify-content: center;
}
.space-between{
  justify-content: space-between;
}
.space-around{
  justify-content: space-around;
}
.wrap{
  flex-wrap: wrap;
}
.font-bold{
  font-weight: bold;
}
.maxw12{
	max-width: 1200px;
	margin: 0 30px;
	width: 100%;
}
.maxw14{
	max-width: 1400px;
	margin: 0 30px;
	width: 100%;
}
.maxw9{
	max-width:900px;
	width: 100%;
}
.maxw11{
	max-width:1200px;
	width: 100%;
}
.maxw18{
	max-width: 1600px;
	margin: 0 30px;
	width: 100%;
}
.maxw6{
	max-width: 600px;
}
.backgound-white{
	background-color: white;
}
.backgound-darkblue{
	background-color: #151448;
}
.backgound-orange{
	background-color: #F3802D;
}


/* General classes */


/* scroll bars */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track {
	background-color: #ededed;
  }
*::-webkit-scrollbar-thumb {
  background-color: #F3802D;
  border-radius: 7px;
}



@-moz-document url-prefix(){
  *{
      scrollbar-width: thin;
  }
}
/* scroll bars */


/*color*/
.back-orange{
	background: #F3802D;
}
.back-lightblue{
	background: #3A6FF8;
}
.font-lightblue{
	color: #3A6FF8;
}
.font-darkblue{
	color:#151448;
}
.font-orange{
	color:#F3802D;
}
.font-grey{
	color: #676767;
}
.font-white{
	color:#fff;
}

/* Button*/
.btn{
    border-radius: 55px;  
	font-weight: 700;
	font-size: 1.3rem;
	padding: 15px 45px ;
	border:none;
	outline: none;
	transition: 0.4s;
	cursor: pointer;
	text-decoration: none;
	min-width: max-content;
}




/* cookie bar */


.cookieConsent{
	position: fixed;
	bottom: 0;
	z-index: 999;
	display: flex;
	flex-direction: column;
	background-color: #292929ec;
	color: #ededed;
	transition: 0.4s;
	overflow: auto;
	max-height: 701px;
	width: 100%;
	align-items: center;
}
.cookieConsent-inner-up{
	display:flex;
	align-items: center;
	justify-content: center;
	padding: 25px;
	flex-wrap: wrap;
	gap: 15px;
	max-width: 1530px;
}
.cookie-text{
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 10px;
	flex: 2;
	min-width: 300px;
	text-align: justify;
}
.cookie-text a{
	color: white;
	margin-right: 5px;
}
.cookie-buttons{
	display:flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
	flex: 1;
	min-width: 300px;
	flex: 1;
}
.cookie-buttons button{
    min-width: max-content;
	border: none;
	border-radius: 7px;
	font-size: 1rem;
	padding: 10px 15px;
	font-weight: bold;
	cursor: pointer;
}
.customize{
	color: #151448;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	gap:5px;
}
.arrow-custom{
    content: "";
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid;
    border-top-color: inherit;
}
.accept-all{
	background-color: #F3802D;
	color: #fff;
}
.decline-all{
	background-color: #fff;
	color: #151448;
}

.cookieConsent-inner-down{
	display:flex;
	flex-direction: column;
	gap: 15px;
	padding: 15px;
	max-width: 1200px;
	transition: 0.4s;
	max-height: 985px;
	overflow: auto;
}
.cookieConsent-inner-down::-webkit-scrollbar-track {
	background-color: transparent;
  }
.cookie-type{
	padding: 5px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
}
.cookie-type-content{
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.cookie-details{
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding:0 65px;

}
.cookie-heading{
	padding: 5px 15px;
	display: flex;
	align-items: center;
	gap: 5px;
}
.cookie-type input[type='checkbox']{
	height: 20px;
	width: 20px;
}
.saveButton{
	border: none;
	border-radius: 7px;
	font-size: 1rem;
	padding: 10px 15px;
	margin: 15px 25px 25px 25px;
	
	font-weight: bold;
	cursor: pointer;
}


/* toogle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    /* cursor: pointer; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbb;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .2s;
  }
  
  input:checked + .slider {
    background-color: #F3802D;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #F3802D;
  }
  .disabled-input{
	cursor: not-allowed !important;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  
  /* Rounded sliders of switch */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }




.switch2 {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }
  
  .switch2 input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider2 {
    position: absolute;
    /* cursor: pointer; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbb;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider2:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .2s;
  }
  
  input:checked + .slider2 {
    background-color: #3A6FF8;
  }
  
  input:focus + .slider2 {
    box-shadow: 0 0 1px #3A6FF8;
  }
  input:checked + .slider2:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  
  /* Rounded sliders of switch */
  .slider2.round2 {
    border-radius: 34px;
  }
  
  .slider2.round2:before {
    border-radius: 50%;
  }



  .cookie-policy-div{
	color: #151448;
	margin-top: 50px;
	padding-bottom: 70px;
	max-width: 1250px;
	display: flex;
	flex-direction: column;
	gap: 35px;
  }
  .cookieConsent-inner-down2{
	display:flex;
	flex-direction: column;
	gap: 15px;
	padding: 15px;	
  }














.App{
	background-image: url("/public/assets/Images/BG.jpg");
	background-position: top;
	background-size: contain;
	background-repeat: no-repeat repeat ;
}
.app-inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	overflow-y: hidden;
	
	
}











.contact-icon{
	position: fixed;
	bottom: 20px;
	right: 35px;
	transition: 0.4s;
	z-index: 99;
	cursor: pointer;
	border: 1px solid #ffffff80;
    border-radius: 50%;
	width: 55px;
}
.contact-icon:hover{
	scale: 1.1;
}
.contact-form{
	position: fixed;
	display: flex;
	flex-direction: column;
	gap: 25px;
	border-radius: 15px;
	background-color:#151448;
	width: 0;
	height: 0;
	max-width: 300px;
	max-height: max-content;
	bottom:90px;
	right: 35px;
	box-shadow: 0px 2px 46px rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	z-index: 100;
	overflow: hidden;
	padding: 0;
}
.contact-form input{
	border-radius: 15px;
	outline: none;
	border: none;
	box-shadow: 0px 2px 20px rgba(58, 111, 248, 0.2);
	padding: 14px 25px;
}
.contact-form textarea{
	
	border-radius: 15px;
	max-width: 100%;
	min-width: 100%;
	min-height: 180px;
	outline: none;
	border: none;
	box-shadow: 0px 2px 20px rgba(58, 111, 248, 0.2);
	padding: 14px 25px;
	flex:0.8;
}
#contact-item4{
	font-size: 1rem;
	width: max-content;
	padding: 10px 30px;
}
.contact-form button:hover{
	background-color: #3A6FF8;
}






















.nav-menu{
	width: 100%;
	padding: 45px 30px;
	position: fixed;
	top:0;
	transition: 0.4s;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99;
}
.nav-menu-inner{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logo img{
	max-width: 250px;
	cursor: pointer;
}
.menu-items{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 50px;
	flex: 1;
}
.menu-item{
	margin-left: 5px;
	margin-right: 20px;
	font-size: 1rem;	
	min-width: max-content;
}
.menu-item p{
	cursor: pointer;
	font-weight: 600;
	color: #676767;
	transition: 0.4s;
}
.menu-item p:hover{
	color: #0F044C;
}
.menu-item a{
	text-decoration: none;
	font-weight: 600;
	color: #676767;
	transition: 0.4s;
}
.menu-item a:hover{
	color: #0F044C;
}
.nav-buttons button:hover{
	background-color: #151448;
}
.mobile-nav{
	display: none;
}






.newsLetter-modal{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.4s;
}
.newsLetter-back{
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000000a2;
	z-index: 101;
}
.newsLetter-close{
	position: absolute;
	top: 15px;
	right: 35px;
	z-index: 101;
	font-size: 2rem;
	cursor:pointer;
	color:rgb(225, 225, 225);
}
.newsLetter-close:hover{

	color:rgb(255, 255, 255);
}
.newsLetter-content{

	background-color: transparent;
	z-index: 105;
	display: flex;
	align-items: center;
	justify-content: center;
}

.newsLetter{
	max-width: 80vw;
	max-height: 80vh;
	padding: 40px 80px;
	/* margin-top: 50px; */
	border-radius: 30px;
	gap:50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background-color: rgb(255, 255, 255);
	flex-wrap: wrap;
	box-shadow: 0px 2px 46px rgba(0, 0, 0, 0.05);
}
.newsLetter-text{
	/* min-width: 400px; */
	color: #151448;
	flex:1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 25px;
}
.newsLetter-text h2{
	font-size: 2rem;
	line-height: 50px;
}
.newsLetter-text p{
	font-size: 1.25rem;
	line-height: 35px;
}
.newsLetter-text button{
	max-width: max-content;
	min-width: 167.36px;
	/* margin-top: 20px; */
	font-size: 1rem;
}
.newsLetter-text button:hover{
	color: #fff;
	background-color: #151448;
}
.newsLetter-image{
	border-radius: 15px;
	min-width: 400px;
	height: 100%;
	display: flex;
	flex: 1;
}
.newsLetter-image img{
	width: 100%;
	height: 100%;
	border-radius: 15px;
}
.newsLetter input{
	border-radius: 15px;
	outline: none;
	border: none;
	box-shadow: 0px 2px 20px rgba(58, 111, 248, 0.3);
	/* margin-top: 20px; */
	height: 50px;
	padding: 14px 25px;
	flex:1;
}






.hero-slide{
	padding: 45px 30px;
	margin-top: 149px;
}
.hero-slide-inner{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    min-height: 600px;
}
.hero-slide-inner h1{
	text-align: center;
	font-size: 3.6rem;
}
.hero-slide-inner p{
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 32px;
	text-align: center;
	color: #0F044C;
}
.hero-slide-inner .hero-btn-link{
	margin-top: 20px;
}
.hero-slide-inner .hero-btn-link:hover{
	background-color: #151448;
}

.hero-slide-inner video{
    /* max-width: 1050px; */
    border-radius: 30px;
    margin-top: 90px;
    box-shadow: 0px 2px 46px rgb(0 0 0 / 20%);
    width: 100%;
    height: auto;
}
















.apps{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 60px 30px;
}
.apps-inner{
	max-width: 600px;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 45px;
}
.apps-inner h2{
	font-weight:normal ;
}
.appList{
	gap: 30px;
}
.appImage{
	width: 60px;
	box-shadow:0px 1.62342px 4.05854px rgba(0, 0, 0, 0.1);
	border-radius:15px;
	-webkit-filter: grayscale(100%); 
	filter: grayscale(100%);
}


















.what-is{
	padding: 45px 30px;
}
.what-is-inner{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
	background-color: rgba(255, 255, 255, 0.6);
	border-radius: 15px;
	padding: 45px 100px;
	box-shadow: 0px 2px 46px rgb(0 0 0 / 3%);

}
.what-is-inner h1{
	text-align: center;
	font-size: 2.2rem;
}
.what-is-inner p{
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 32px;
	text-align: center;
	color: #0F044C;
}


















.cards{
	padding: 60px 30px;
}
.cards-inner{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    /* min-height: 800px; */
}
.cards-inner-p{
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 32px;
	text-align: center;
	color: #0F044C;
}
.cards-inner h1{
	text-align: center;
	font-size: 2.2rem;
}
.cardList{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap:35px;
	margin-top: 60px;
    min-height: 475px;
	z-index: 1;

}
.card{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap:25px;
	transition: 0.4s;
	background-color: #fff;
	box-shadow: 0px 2px 20px rgba(58, 111, 248, 0.2);
	border-radius: 30px;	
	padding: 20px;
	min-width: 320px;
	/* width: 330px; */
	max-width: 360px;
	min-height: 435px;
}
.card-image{
	height: 135px;
}
.card-p{
	text-align: center;
	line-height: 32px;
	font-size: 1.2rem;
}
.card a{
	opacity: 0;
	margin-top: -40px;
}
.card:hover{
	scale:1.05;
	box-shadow: 0px 2px 20px rgba(243, 128, 45, 0.25);
	max-height: 450;
}
.card:hover a{
	opacity: 1;
	margin-top: 0;
}
.card a:hover{
	background-color: #151448;
}












.important{
	padding: 45px 0;
	margin-bottom: 45px;
}
.important-inner{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.important-inner-div{
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.important-div{
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-height: 440px;
	flex-wrap: wrap;
	gap:70px;
	padding:0 30px;
	z-index: 1;

}
.important-div-image{
	display: flex;
	align-items: center;
	justify-content: center;
	/* flex:1; */
	border-radius:15px 15px 0 0;
	box-shadow: 0px -20px 54px rgba(58, 111, 248, 0.1);
	background-color: #fff;
	padding: 30px;
	width: 330px;
	height: 380px;
}
.important-div-image img{
	width: 100%;
	height: auto;
}
.important-div-text{
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex:1;
	padding: 30px 0 50px 30px;
	min-width: 400px;
	gap: 20px;
}
.important-div-text h3{
	font-size: 2rem;
}
.important-div-text p{
	line-height: 40px;
	font-size: 1.25rem;
}











.features{
	padding: 45px 30px;
}
.features-inner{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap:40px;
}
.features-inner h1{
	text-align: center;
    font-size: 2.2rem;
}
.feature-first{
	padding: 40px;
	margin-top: 50px;
	border-radius: 30px;
	/* width: 100%; */
	gap:30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	flex-wrap: wrap;
	min-height: 483px;
}
.feature-last{
	padding: 40px;
	border-radius: 30px;
	/* width: 100%; */
	gap:30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	flex-wrap: wrap;
	min-height: 483px;

}
.feature-first-text, .feature-last-text{
	min-width: 400px;
	flex:1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 25px;
}
.feature-first-text h2, .feature-last-text h2{
	font-size: 2rem;
	line-height: 48px;
	color:#151448;
}
.feature-first-text p, .feature-last-text p{
	font-size: 1.25rem;
	line-height: 38px;
	font-weight: 600;
	color:#151448;
}
.feature-first-text button, .feature-last-text button{
	max-width: max-content;
	min-width: max-content;
	margin-top: 20px;
}
.feature-first-text button:hover, .feature-last-text button:hover{
	color: #fff;
	background-color: #151448;
}
.feature-first-image, .feature-last-image{
	border-radius: 15px;
	min-width: 400px;
	height: 100%;
	display: flex;
	flex: 1;
}
.feature-first-image img, .feature-last-image img{
	width: 100%;
	height: auto;
	border-radius: 15px;
	/* min-width: 350px; */
}


.feature-rest{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	/* width: 100%; */

}
.feature-row{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 40px;
	flex-wrap: wrap;
	/* margin: 40px; */
}
.feature{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	border-radius: 30px;
	padding: 40px;
	gap:20px; 
	flex: 1;
	color:#151448;
	min-height: 483px;

	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
}
.feature p{
	font-size: 1.1rem;
	line-height: 31px;
    font-weight: 600;
}
.feature img{
	width: 100%;
	height: auto;
	border-radius: 15px;
	min-width: 350px;
}




















.use-cases{
	padding: 100px 30px;
}
.use-cases-inner{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
	min-height: 1080px;
    position: relative;
}
.use-cases-inner-p{
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 32px;
	text-align: center;
	color: #0F044C;
}
.use-cases-inner h1{
	text-align: center;
	font-size: 2.2rem;
}
.use-caseList{
	display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
    margin-top: 60px;
    min-height: 460px;
    position: absolute;
    top: 60px;
    left: 0;
}
.use-case{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap:25px;
	transition: 0.4s;
	background-color: #fff;
	box-shadow: 0px 2px 20px rgba(58, 111, 248, 0.2);
	border-radius: 30px;	
	padding: 20px;
	min-width: 320px;
	max-width: 550px;
	min-height: 400px;
}
.use-case-image{
	width: 140px;
}
.use-case-title{
	text-align: center;
}
.use-case-p{
	text-align: center;
	line-height: 32px;
	font-size: 1.2rem;
}
.use-case button{
	opacity: 0;
	margin-top: -40px;
}
.use-case:hover{
	scale:1.05;
	box-shadow: 0px 2px 20px rgba(243, 128, 45, 0.25);
	min-height: 450px;

}
.use-case:hover button{
	opacity: 1;
	margin-top: 0;
}
.use-case button:hover{
	background-color: #151448;
}


.use-case-modal{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;

	display: flex;
	align-items: center;
	justify-content: center;
}
.use-case-modal-back{
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000000a2;
	z-index: 101;
}
.use-case-modal-content{
	width: 80%;
	height: 80%;
	background-color: transparent;
	z-index: 105;
}






















.demos{
	padding: 20px 30px 90px 30px;
}
.demo-inner{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap:40px;
}
.demo-inner h1{
	text-align: center;
    font-size: 2.2rem;
}
.demo{
	padding: 40px 80px;
	margin-top: 50px;
	border-radius: 30px;
	gap:50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background-color: rgba(255, 255, 255, 0.6);
	flex-wrap: wrap;
	box-shadow: 0px 2px 46px rgba(0, 0, 0, 0.05);
}
.demo-text{
	min-width: 400px;
	color: #151448;
	flex:1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 25px;
}
.demo-text h2{
	font-size: 2rem;
	line-height: 50px;
}
.demo-text p{
	font-size: 1.25rem;
	line-height: 35px;
}
.demo-text button{
	max-width: max-content;
	min-width: max-content;
	margin-top: 20px;
	font-size: 1rem;
}
.demo-text button:hover{
	color: #fff;
	background-color: #151448;
}
.demo-image{
	border-radius: 15px;
	min-width: 400px;
	height: 100%;
	display: flex;
	flex: 1;
}
.demo-image img{
	width: 100%;
	height: 100%;
	border-radius: 15px;
}
.demo input{
	border-radius: 15px;
	outline: none;
	border: none;
	box-shadow: 0px 2px 20px rgba(58, 111, 248, 0.2);
	margin-top: 20px;
	height: 50px;
	padding: 14px 25px;
	flex:0.8;
}




















.switch-price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: transparent;
    margin: 0 auto;
    margin-bottom: 40px;
    /* margin-top: 15px; */
}
.switch-price .choicePrice{
    color: #a7a7a7;
}
.price-table {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 0 auto;
    height: 810px;
    /* max-height: 800px; */
    max-width: 1375px;
    flex-wrap: wrap;
	padding-bottom: 25px;
  }
.table13{
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
    flex:1;
    height: 100%;
}
.table13-inner{
    background-color: #ffffffa3;
    /* padding: 35px; */
    display: flex;
    flex-direction: column;
    height: 95%;
    padding: 35px;
}
.table-btn-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.table-btn-div a{
	text-align: center;
	text-decoration: none;
    color: white;
    background-color: #3A6FF8;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.825em 1.1em;
    margin-top: 20px;
    min-width: max-content;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.free-btn{
    background-color: #3A6FF8;
    cursor:'pointer';
    color:#fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1) !important;
}
.table-btn-div span{
    font-size: 0.9rem;
    color: #676767;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;
}
.table-desc-div{
    display: flex;
    flex-direction: column;
}
.table-desc-div span{
    color: #676767;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: justify;
    margin: 10px 0;
    line-height: 24px;
}
.table-desc-div hr{
   width: 100%;
   border:1px solid #ddd;
}
.table-title-div{
    display: flex;
    align-items: center;
    gap: 5px;
}
.table h3{
    margin: 0;
    color:#0f044c;
}
.table ul{
    margin: 0;
    margin-top: 10px;
    padding: 0;
}
.table ul li{
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    gap: 10px;
}
.table ul li span{
    font-size: 1rem;
    font-weight: 700;
    color:#676767;
    line-height: 24px;
}

.table2{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex:1;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
    height: 100%;
    padding: 35px;
    z-index: 2;
}
.table-price{
    color: #0f044c;
}
.table-price-span{
    font-size: xxx-large;
    font-weight: 700;
}
.table{
    min-width: 343px;
}



















.footer{
	padding: 40px 30px 0 30px;
	width: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	background-color: #fff;

}
.footer-inner{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 40px 0;
	gap: 30px;
}
.footer-logo-div{
	flex:1.5;
	min-width: 250px;
}
.footer-logo-div img{
	max-width: 200px;
}
.footer-quick-links{
	display: flex;
	flex-direction: column;
	gap:6px
}
.footer-others{
	display: flex;
	flex-direction: column;
	gap:6px
}
.footer-contact-us{
	display: flex;
	flex-direction: column;
	gap:6px
}
.footer-bottom{
	display: flex;
	justify-content: center;
	padding: 40px 0;
	border-top: 1px solid rgba(193, 193, 193, 0.3);
	width: 100%;
}
.footer-section{
	flex:1;
	min-width: 200px;
}
.footer-section a{
	font-weight: 400;
	font-size: 1rem;
	text-decoration: none;
	color: #2A2C33;
}
.footer-section span{
	font-weight: 400;
	font-size: 1rem;
	color: #2A2C33;
	cursor: pointer;
}






















/* Resposive*/


.mobile-nav-menu{
	border-radius: 15px;
    box-shadow: 0 2px 46px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    height: 0;
    max-width: 300px;
    overflow: hidden;
    position: fixed;
    right: 15px;
    top: 100px;
    transition: .3s;
    width: 0;
    z-index: 99;
}
.mobile-first-item{
	border-radius: 15px 15px 0 0;
}
.mobile-menu-item{
	background-color: #fff;
	display: none;
	padding: 15px;
	border-bottom: 1px solid rgba(42, 44, 51, 0.3);
	font-weight: 600;
    font-size: 1.2rem;
	transition: 0.4s;
	cursor: pointer;
}
.mobile-nav-menu span{
	color: unset;
}
.mobile-nav-menu div{
	min-width: max-content;	
}
.mobile-menu-item:hover{
	background-color: #151448;
	color: #fff;
}
.mobile-menu-item:hover{
	background-color: #151448;
	color: #fff;
}
.mobile-link1{
	text-decoration: none;
	color: unset;
	background-color: #fff;
	width: 100%;
	border-radius: 0 ;
}
.mobile-link2{
	text-decoration: none;
	color: #fff !important;
	background-color: #F3802D;
	width: 100%;
	border-radius: 0 0 15px 15px;
}
.mobile-nav img{
	cursor: pointer;
	transition: 0.4s;
	width: 50px;
}
.mobile-nav img:hover{
	scale:1.1
}




/* contact form */
@media screen and (max-width: 360px)  {
	.contact-form, .contact-icon{
		right:15px;
		max-width: 90vw;
	}

}
@media  screen and (max-height:512px) {
	.contact-form{
		right: unset;
		bottom : unset;
		top: 13px;
		max-height: 91vh;
		overflow: auto;
		
	}
	
}

/* newsletter */
@media screen and (max-width:768px) and (orientation: portrait) {

	.newsLetter-text h2{
		font-size: 1.5rem;
		line-height: 35px;
	}
	.newsLetter-text p{
		font-size: 1rem;
		line-height: 25px;
	}
	.newsLetter-text button{
		max-width: none;
		width: 100% !important;
	}
}
@media screen and (max-width:1135px) and (orientation: landscape) {

	.newsLetter-text h2{
		font-size: 1.5rem;
		line-height: 35px;
	}
	.newsLetter-text p{
		font-size: 1rem;
		line-height: 25px;
	}
}
@media screen and (max-width:894px) and (orientation: landscape) {
	.newsLetter{
		padding: 30px 40px;
		max-width: 90vw;
	}
	.newsLetter-close{
		top:10px;
		right: 25px;
	}
}
@media screen and (max-width:707px) and (orientation: landscape) {

	.newsLetter{
		padding: 25px 35px;
		max-width: 90vw;
	}
	.newsLetter-text{
		gap:20px;
	}
	.newsLetter-text h2{
		font-size: 1.35rem;
		line-height: 30px;
	}
	.newsLetter-text p{
		font-size: 0.9rem;
		line-height: 22px;
	}
}

@media screen and (max-height:335px) {
	.newsLetter{
		padding: 18.5px 35px;
	}
	.newsLetter-text{
		gap: 19px;
	}
	.newsLetter-text h2{
		font-size: 1.3rem;
		line-height: 15px;
	}
	.newsLetter-text p{
		font-size: 0.85rem;
		line-height: 18px;
	}
	.newsLetter-text span{
		font-size: 0.7rem !important;
	}
	.newsLetter-close{
		top:10px;
		right: 10px;
	}
}


@media screen and (max-width:612px) and (orientation: portrait) {
	.newsLetter{
		padding: 30px 40px;
		max-width: 90vw;
	}
	.newsLetter-text{
		gap:15px
	}
	.newsLetter-text h2{
		font-size: 1.3rem;
		line-height: 30px;
	}
	.newsLetter-text p{
		font-size: 0.95rem;
		line-height: 22px;
	}
	.newsLetter-text button{
		max-width: none;
		width: 100% !important;
		padding: 10px 40px;
	}
	.newsLetter-text span{
		line-height: 18px;
	}
}


/*nav-menu*/
@media screen and (max-width: 1180px)  {
	.mobile-nav{
		display: block;
		position: fixed;
		top:45px;
		right: 35px;
		z-index: 98;
		transition: 0.4s;
	}
	.menu-items, .nav-buttons{
		display: none;
	}
}
@media screen and (max-height: 512px)  {
	.mobile-nav-menu{
		max-height: 60vh;
		overflow: auto;
	}
	.contact-form{
		bottom: 80px;

	}

}


@media screen and (max-width: 400px) {
	.logo img {
		max-width: 195px;
	}
	.mobile-nav{
		top:45px;
	}
	.mobile-nav img{
		width: 40px;
		margin-left: 20px;
	}
	.maxw14{
		margin: 0 15px;
	}
}

@media screen and (max-width: 319px) {
	.logo img {
		max-width: 170px;
		margin-top: 2px;
	}
}

@media screen and (max-width: 296px) {
	.logo img {
		margin-left: 0px;
	}
	.maxw14{
		margin: 0;
	}
}

/*hero*/
@media screen and (max-width: 1000px) {
	.hero-slide-inner h1 {
		font-size: 3rem;
	}

}
@media screen and (max-width: 604px) {
	.hero-slide-inner h1 {
		font-size: 2.6rem;
	}
	.hero-slide-inner p {
		font-size: 1.1rem;
	}
}
@media screen and (max-width:415px) {
	.hero-slide{
		padding: 45px 20px;
	}
}

/* what is*/
@media screen and (max-width:790px) {
	.what-is{
		padding: 45px 20px;
	}
	.what-is-inner{
		padding: 45px 50px;
	
	}
}
@media screen and (max-width:615px) {
	.what-is-inner{
		padding: 45px 20px;

	}
}
@media screen and (max-width:415px) {
	.what-is-inner p{
		font-size: 1.1rem;

	}
}


/* Important*/
@media screen and (max-width: 860px){
	.important-div-image{
		border-radius: 15px;
	}
	.first-important{
		padding-top: 100px;
	}
	.odd-div .important-div-image{
		margin-bottom: 100px;
	}
	.odd-div{
		margin-top: 50px;
	}
	.important-div-text:not(.odd-div .important-div-text){
		padding-bottom: 80px;
	}

}
@media screen and (max-width:1101px) {
	.important-div-text h3{
		font-size: 1.5rem;
		line-height: 35px;
	}
	.important-div-text p{
		line-height: 35px;
		font-size: 1.1rem;
	}
}

@media screen and (max-width:415px) {

	.important-div-text{
		min-width: 300px;
		padding: 30px 0 50px 0px;
	}
	.important-div-text h3{
		font-size: 1.25rem;
		line-height: 35px;
	}
	.important-div-text p{
		line-height: 35px;
		font-size: 1rem;
	}
}
@media screen and (max-width:319px) {

	.important-div-text{
		min-width: unset;
		padding: 30px 0 50px 0px;
	}
	.important-div-text h3{
		font-size: 1.25rem;
		line-height: 35px;
	}
	.important-div-text p{
		line-height: 35px;
		font-size: 1rem;
	}
}

/*features*/
@media screen and (max-width:615px){
	.features{
		padding: 45px 20px;
	}
	.features-inner{
		gap:20px;
	}
	.feature-first, .feature-last{
		padding: 30px 20px 20px 20px;
	}
	.feature-first-text, .feature-last-text{
		min-width: 270px;
	}
	.feature-first-text h2, .feature-last-text h2{
		font-size: 1.5rem;
		line-height: 35px;
	}
	.feature-first-text p, .feature-last-text p{
		font-size: 1rem;
		line-height: 28px;
	}
	.feature-first-image, .feature-last-image{
		min-width: 270px;
	}

	.feature-row{
		gap:20px
	}
	.feature-rest{
		gap:20px
	}
	.feature{
		padding: 20px 20px 30px 20px;
		min-height: unset;
	}
	.feature p{
		font-size: 1rem;
    	line-height: 28px;
	}
	.feature img {

		min-width: 250px;
	}
}

/* pricing */
@media screen and (max-width:1022px) {
	.table{
		min-height: 722px;
	}
	.table13-inner{
		min-height: 722px;
	}
	.price-table{
		height: unset;
	}
}
@media screen and (max-width:686px) {
	.table{
		min-height: max-content;
	}
	.table13-inner{
		min-height: max-content;
	}
	
}
@media screen and (max-width:686px) {
	.table{
		min-width: unset;
	}

	
}


/* use-cases */
@media screen and (max-width:1205px) {
	.use-cases-inner{
		min-height: 1910.66px;
	}
}
@media screen and (max-width:540px) {
	.use-cases-inner{
		min-height: 2010.66px;
	}
}
@media screen and (max-width:450px) {
	.use-cases-inner{
		min-height: 2110.66px;
	}	
}

@media screen and (max-width: 380px) {
	.use-case{
		min-width: unset;
	}
	.use-cases-inner{
		min-height: 2160.66px;
	}	
}
@media screen and (max-width: 380px) {

	.use-cases-inner{
		min-height: 2250.66px;
	}	
}
@media screen and (max-width: 336px) {

	.use-cases-inner{
		min-height: 2300.66px;
	}	
}
@media screen and (max-width: 323px) {

	.use-cases-inner{
		min-height: 2500.66px;
	}	
	.use-caseList{
		left:unset;
	}
}

/*demo*/
@media screen and (max-width:615px){
	.demos{
		padding: 20px 10px 90px 10px;
	}
	.demo{
		padding: 40px 20px;
	}
	.demo-text{
		min-width: 250px;
	}
	.demo-text h2{
		font-size: 1.5rem;
		line-height: 40px;
	}
	.demo-text p{
		font-size: 1rem;
		line-height: 28px;
	}
	.demo-image{
		display: none;
	}
}


/*footer*/
@media screen and (max-width:1000px) {
	.footer-section{
		flex: none;
		min-width: 45%;
	}
	.footer-logo-div{
		flex: none;
		min-width: 45%;
	}

}
@media screen and (max-width:768px) {
	.footer-section{
		text-align: center;
		min-width: 100%;
	}
	.footer-logo-div{
		text-align: center;
		min-width: 100%;
	}
	.footer-inner{
		gap:60px
	}
}


















